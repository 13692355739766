import { Directive, Input, Renderer2, TemplateRef, ViewContainerRef } from '@angular/core';
import { EnvironmentConfig, Layout } from '@bs/models/common/environment-config';
import { WindowService } from '@bs/universal/window.service';

/**
 * the layout renderer directive
 */
@Directive({
  selector: '[layoutRender]',
  standalone: true
})
export class LayoutRenderDirective {

  /**
   * is checking if the application is running on desktop viewport to resolve some logic
   */
  isDesktop = false;
  /**
   * it's rendering by default specified templates
   */
  private defaultTpl: Layout = { footer: 'center', header: 'center', lobby: 'default' };

  /**
   * The constructor
   *
   * @param viewContainer
   * @param renderer
   * @param templateRef
   * @param config
   * @param windowService
   */
  constructor(private viewContainer: ViewContainerRef, private renderer: Renderer2, private templateRef: TemplateRef<any>, private config: EnvironmentConfig,
              windowService: WindowService) {
    windowService.device$.subscribe({
      next: device => this.isDesktop = device.isDesktop
    });
  }

  /**
   * rendering a different specified layout in the environment config
   */
  @Input()
  set layoutRender(currentType: string) {

    const vv = this.viewContainer.injector;

    const tag = vv['_tNode'].value;

    //fix for lisa-footer replacing to footer, or replace casino games-grid to lobby
    const tagValue = tag.replace('lisa-', '').replace('games-grid', 'lobby');

    if (this.config.layout) {

      const layoutType = this.config.layout[tagValue];

      if (layoutType === null) {
        this.viewContainer.clear();
        return;
      }

      if (!layoutType && this.defaultTpl[tagValue] === currentType) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        return;
      }

      if (layoutType !== currentType && this.isDesktop) {
        this.viewContainer.clear();
      } else {
        const view = this.viewContainer.createEmbeddedView(this.templateRef);
        this.renderer.setAttribute(view.rootNodes[0], 'layoutRender', currentType);
      }

    } else {
      if (this.defaultTpl[tagValue] === currentType) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    }
  };

}

/**
 * Example usage:
 *
 * @example
 * <footer *layoutRender="'fullWidth'"></footer>
 */
